import { Button, Card, CardActions, CardHeader, Typography } from '@material-ui/core'
import React from 'react'

export default function FeePaymentAll() {
    return (
        <div>
            <Card raised style={{minHeight: 220}}>
                <CardHeader
                    title={<Typography variant="h5" style={{ fontWeight: 500 }}>Fee Payment for Arrear & Examination</Typography>}
                    subheader="For Examination & Admission Fee Payment"
                />

                <CardActions>
                    {/* <Button href="/feepayment" variant="contained" color="primary" size="small">Proceed to Fee Payment</Button> */}
                    <Button href="/feepayment-arrear" variant="contained" color="primary" size="small">Click here for Arrear Fee Payment</Button>
                </CardActions>
            </Card>


        </div>
    )
}
